import React from "react"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Premios from "../icons/Premios"
import { StaticImage } from "gatsby-plugin-image"
const Implementacion = () => {
    return (
    <div id = "premios" >
        <h1>Expansión de la Implementacion</h1>
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#F2F2F2', color: '#2f3e8d' }}
                contentArrowStyle={{ borderRight: '7px solid  #2f3e8d' }}
                date="2019"
                iconStyle={{ background: '#9cc444', color: '#fff' }}
                icon={<Premios />}
            
            >
                <h3 className="vertical-timeline-element-title">
                    Fase Inicial
                </h3>
                <p>
                    7 Establecimientos, 14 Servicios
                </p>
                <StaticImage
                        src = "../../assets/resultados/telesan_implementacion1.png"
                        width = {400}
                        alt= "Telesan implementación 1"
                    />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#F2F2F2', color: '#2f3e8d' }}
                contentArrowStyle={{ borderRight: '7px solid  #2f3e8d' }}
                date="2020"
                iconStyle={{ background: '#9cc444', color: '#fff' }}
                icon={<Premios />}
            
            >
                <h3 className="vertical-timeline-element-title">
                    Mediados de 2020
                </h3>
                <p>
                  27 Establecimientos y 17 servicios en 5 departamentos
                </p>
                <StaticImage
                        src = "../../assets/resultados/telesan_implementacion2.png"
                        width = {400}
                        alt= "Telesan implementación 2"
                    />
            </VerticalTimelineElement>
            <VerticalTimelineElement
                contentStyle={{ background: '#F2F2F2', color: '#2f3e8d' }}
                contentArrowStyle={{ borderRight: '7px solid  #2f3e8d' }}
                         className="vertical-timeline-element--work"
       date="2020"
                iconStyle={{ background: '#9cc444', color: '#fff' }}
                icon={<Premios />}
            >
                <h3 className="vertical-timeline-element-title">
                   Final 2020
                </h3>
                <p>
                  38 Establecimientos y  25 servicios en 6 departamentos.
                </p>
                <StaticImage
                        src = "../../assets/resultados/telesan_implementacion3.png"
                        width = {400}
                        alt= "Telesan implementación 3"
                    />
            </VerticalTimelineElement>
           
        </VerticalTimeline>
    </div>
    )
}

export default Implementacion;
