import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import Hero from "../components/resultados/Hero"
import Implementacion from "../components/resultados/Implementacion"
import Galeria from "../components/resultados/Galeria"
import Covid from "../components/resultados/Covid"
const IndexPage = (props) => (
  <Layout>
    <Seo title="Resultados"/>
    <Hero/>
    <Implementacion/>
    <Galeria/>
    <Covid/>
  </Layout>
);

export default IndexPage;
