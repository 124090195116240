import React from 'react';
import ImageGallery from 'react-image-gallery';

import equipo1 from '../../assets/resultados/equipo/telesan_equipo1.jpg'
import equipo2 from '../../assets/resultados/equipo/telesan_equipo2.jpg'
import equipo3 from '../../assets/resultados/equipo/telesan_equipo3.jpg'
import equipo10 from '../../assets/resultados/equipo/telesan_equipo10.jpg'
import equipo11 from '../../assets/resultados/equipo/telesan_equipo11.jpg'
import equipo12 from '../../assets/resultados/equipo/telesan_equipo_12.jpg'
import equipo13 from '../../assets/resultados/equipo/telesan_equipo13.jpg'


const images = [
        {
          original: equipo1,
          thumbnail: equipo1,
        },
        {
          original: equipo2,
          thumbnail: equipo2,
        },
        {
          original: equipo3,
          thumbnail: equipo3,
        },
        {
        original: equipo10,
        thumbnail: equipo10,
        },
        {
        original: equipo11,
        thumbnail: equipo11,
        },
        {
        original: equipo12,
        thumbnail: equipo12,
        },
        {
        original: equipo13,
        thumbnail: equipo13,
        }                
];
      

export default function ImageGridList() {

  return (
        <section id = "telesan-hero2" className="equipo">
        <ImageGallery 
                items={images} 
                showNav = {true}
                showFullscreenButton = {true}
                
        />
    </section>
  );
}