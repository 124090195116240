import React from 'react';
import EquipoGaleria from "./EquipoTeleSalud"
import HospitalGaleria from "./Hospitales" 



const Galeria = () => {
    return (
        <section id = "telesan-hero2">
            <div className = "content-telesan">     
                <h1>Equipo para TeleSalud</h1>
                <EquipoGaleria/>           
            </div>
            <div className = "content-telesan">
                <h1>Hospitales equipados</h1>
                <HospitalGaleria/>
            </div>
        
        </section>
    
    )
}

export default Galeria