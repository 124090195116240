import React from 'react';

 const Covid = () => {
    return (
        <section id = "telesan-hero1">
            <div className = "content-telesan">
                <div className = "row">
                    <div className = "column">
                        <h1>TeleEducación</h1>
                        <div className = "video-container">
                            <iframe  
                            src="https://www.youtube.com/embed/videoseries?list=PLmKftbz_4YHfkEF7cymiaZYn7dXhVTtyv" 
                            title="YouTube video player" 
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    )
 }


 export default Covid