import React from 'react';
import ImageGallery from 'react-image-gallery';

import equipo4 from '../../assets/resultados/equipo/telesan_equipo4.jpg'
import equipo5 from '../../assets/resultados/equipo/telesan_equipo5.jpg'
import equipo6 from '../../assets/resultados/equipo/telesan_equipo6.jpg'
import equipo7 from '../../assets/resultados/equipo/telesan_equipo7.jpg'
import equipo8 from '../../assets/resultados/equipo/telesan_equipo8.jpg'
import equipo9 from '../../assets/resultados/equipo/telesan_equipo9.jpg'
import equipo14 from '../../assets/resultados/equipo/telesan_equipo14.jpg'


const images = [
        {
          original: equipo4,
          thumbnail: equipo4,
        },
        {
          original: equipo5,
          thumbnail: equipo5,
        },
        {
          original: equipo6,
          thumbnail: equipo6,
        },
        {
        original: equipo7,
        thumbnail: equipo7,
        },
        {
        original: equipo8,
        thumbnail: equipo8,
        }  ,  
        {
          original: equipo9,
          thumbnail: equipo9,
        },
        {
          original: equipo14,
          thumbnail: equipo14,
        }           
];
      

export default function ImageGridList() {

  return (
        <ImageGallery 
                items={images} 
                showNav = {true}
                showFullscreenButton = {true}
                
        />
  );
}